
import $ from "jquery";
import moment from "moment";
import React, { Component } from "react";
import ReactTooltip from "react-tooltip";
import { selectOptionsGenerator } from "../ReusableComponents/OptionsGenerator";
import { formObjDataMapper } from "../ReusableComponents/formObjDataMapper";
import { can_edit, can_view_version } from "../ReusableComponents/urlGenerator";
import { disable_button, validationParser, } from "../ReusableComponents/validationParser2";
import Footer from "./Footer";
import Patientinfoheader from "./Patientinfoheader";
import Sidenav from "./Sidenav";
import { AppTopNav } from "./Topnav";
import ViewHistoryModal from './Modals/Modal/ViewHistoryModal'
import DateTime from "react-datetime";
import { LabelField } from "../ReusableComponents/LabelnInput";
import ConfirmBeforeLeave from "./ConfirmBeforeLeave";
import { encryptStorage } from "./LocalStorageInterceptor";
import { Alert } from "./toastify";
import axios from "axios";
import { showSuccessToast } from "./toastService";
import HOC from "../HOC";

var validationObj = {};
var context = "";

class Supplementaryinfo extends Component {
  constructor() {
    super();
    context = this;
    this.handleId = this.handleId.bind(this);
    this.handleEvent = this.handleEvent.bind(this);
    this.getTimeStamp = this.getTimeStamp.bind(this);
    var now = new Date();
    this.state = {
      supplementaryInfoData: this.getModal(),
      required: this.getModal(),
      labelText: this.getModal(),
      tooltips: this.getModal(),
      tooltipsVisibility: this.getModal(),
      maxLength: this.getModal(),
      //Select variables
      eventCauseTraumaSelectOptions: [],
      attemptedSuicideSelectOptions: [],
      treatmentLocationSelectOptions: [],
      stateAssessLocationSelectOptions: [],
      anyAlcoholResponseSelectOptions: [],
      barbituratesResponseSelectOptions: [],
      benzodiazepinesReponseSelectOptions: [],
      cocaineResponseSelectOptions: [],
      ghbResponseSelectOptions: [],
      hallucinogensPsyResponseSelectOptions: [],
      inhalantsResponseSelectOptions: [],
      ketamineResponseSelectOptions: [],
      marijuanaResponseSelectOptions: [],
      methaAmphResponseSelectOptions: [],
      opioidsResponseSelectOptions: [],
      ifOpioidsResponseSelectOptions: [],
      tranquilizersResponseSelectOptions: [],
      otherResponseSelectOptions: [],
      everOverdosedSelectOptions: [],
      stateAssessmentSelectOptions: [],
      stateAssessmentWhereSelectOptions: [],
      // code Added by ashraf start
      anyAlcoholRouteOptions: [],
      barBituratesRouteOption: [],
      benzodiazepinesRouteOption: [],
      cocaineRouteOption: [],
      ghbRouteOption: [],
      hallucinogensPsyRouteOption: [],
      inhalantsRouteOption: [],
      ketamineRouteOption: [],
      marijuanaRouteOption: [],
      methaAmphRouteOption: [],
      opioidsRouteOption: [],
      tranquilizersRouteOption: [],
      otherRouteOption: [],
      // code Added by ashraf end
      startDateintakedate: new Date(),
      month1followupdate: new Date(
        now.getFullYear(),
        now.getMonth() + 1,
        now.getDate()
      ),
      sixmonthfollow: new Date(
        now.getFullYear(),
        now.getMonth() + 6,
        now.getDate()
      ),
      dob: "",
      startDate: moment().subtract(29, "days"),
      endDate: moment(),
      unSaved: false,
      peerSupportOptions: [],

      //  ==================================================  Code added by Kuldeep Singh ============================================================================================================
      viewHistoryModal: false,
      scheduledDateStateAssessmentSelectOptions: [],
      scheduled_date: "",
      object_id: 0
    };
  }

  getModal() {
    var supplementaryInfoModel = {
      supp_info_date: "",
      events_caused_trauma: "",
      attempted_suicide: "",
      sub_abuse_treatment: "",
      treatment_location: "",
      treatment_loc_other: "",
      state_assess_loc: "",
      state_assess_loc_other: "",
      last_assess_date: "",
      any_alcohol_response: "",
      any_alcohol_days: "",
      barbiturates_response: "",
      barbiturates_days: "",
      benzodiazepines_reponse: "",
      benzodiazepines_days: "",
      cocaine_response: "",
      cocaine_days: "",
      ghb_response: "",
      ghb_days: "",
      hallucinogens_psy_response: "",
      hallucinogens_psy_days: "",
      inhalants_response: "",
      inhalants_days: "",
      ketamine_response: "",
      ketamine_days: "",
      marijuana_response: "",
      marijuana_days: "",
      metha_amph_response: "",
      metha_amph_days: "",
      opioids_response: "",
      if_opioids_days: "",
      if_opioids_response: "",
      if_opioids_specify: "",
      tranquilizers_response: "",
      tranquilizers_days: "",
      other_response: "",
      other_days: "",
      other_please_specify: "",
      ever_overdosed: "",
      times_overdosed: 0,
      // code added by Ashraf start
      any_alcohol_route: "",
      barbiturates_route: "",
      benzodiazepines_route: "",
      cocaine_route: "",
      ghb_route: "",
      hallucinogens_psy_route: "",
      inhalants_route: "",
      ketamine_route: "",
      marijuana_route: "",
      metha_amph_route: "",
      opioids_route: "",
      tranquilizers_route: "",
      other_route: "",
      // code added by Ashraf End

      scheduled_date: "",
    };
    return supplementaryInfoModel;
  }

  handleChange = (date) => {
    this.setState({
      startDateintakedate: date,
      unSaved: true,
    });
  };

  handlemonth1followupdate = (date) => {
    this.setState({
      month1followupdate: date,
      unSaved: true,
    });
  };

  handleChangesixmonthfollow = (date) => {
    this.setState({
      sixmonthfollow: date,
      unSaved: true,
    });
  };

  handleChangedob = (date) => {
    this.setState({
      dob: date,
      unSaved: true,
    });
  };

  componentWillMount() {
    axios
      .get(
        global.restApiURL +
        "webservice/rest/class/id/10?apikey=" +
        sessionStorage.getItem("__CL_TOKEN__")
      )
      .then((res) => {
        var response = res.data;
        if (response.success && res.status == 200) {
          validationObj = validationParser(
            response.data.layoutDefinitions.children[0].children
          );
          this.setState({
            labelText: validationObj.title,
            required: validationObj.mandatory,
            tooltips: validationObj.tooltip,
            tooltipsVisibility: validationObj.tooltipVisibility,
            maxLength: validationObj.columnLength,
          });
          this.createSelectOptions();
        } else if ("error_code" in response) {
          console.log("Session Out");
          window.location.href = global.domain + global.traversArray["out"];
        }
      })
      .catch((err) => {
        // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        console.error(err);
      });
  }

  componentDidMount() {
    const { navigate } = this.props;

    disable_button("supplementaryInfoForm");
    var prevExist = false;
    sessionStorage.setItem("responceId", "");
    if (sessionStorage.getItem("clientId") !== "") {
      axios.get(global.restApiURL + "webservice/rest/object-list?apikey=" + sessionStorage.getItem("__TOKEN__") + '&limit=1&objectClass=SupplementaryInformation&q={"client_id":"' + sessionStorage.getItem("clientId") + '"}').then((res) => {
        const responce = res.data;
        if (responce.data.id !== "" && responce.data.id !== undefined) {
          prevExist = true;
          axios.get(global.restApiURL + "webservice/rest/object/id/" + responce.data.id + "?apikey=" + sessionStorage.getItem("__TOKEN__")).then((res) => {
            sessionStorage.setItem("responceId", res.data.data.id);
            const responce = res.data.data.elements;
            this.setState({
              supplementaryInfoData: formObjDataMapper(responce, this.getModal()),
            }, () => { this.createSelectOptions() });
          })
            .catch((err) => {
              // log request error and prevent access to undefined state
              this.setState({ loading: false, error: true });
              console.error(err);
            });
        } else {
          prevExist = false;
        }
      })
        .catch((err) => {
          // log request error and prevent access to undefined state
          this.setState({ loading: false, error: true });
          console.error(err);
        });
    }

    $.validator.setDefaults({
      submitHandler: function () {
        var jsonData = "";
        if (prevExist === true) {
          jsonData = {
            className: "SupplementaryInformation",
            key: sessionStorage.getItem("clientId"),
            id: sessionStorage.getItem("responceId"),
            published: true,
          };
        } else {
          jsonData = {
            className: "SupplementaryInformation",
            key: sessionStorage.getItem("clientId"),
            published: true,
          };
        }
        jsonData.elements = [];
        var formData = $("#supplementaryInfoForm").serializeArray();
        $.each(formData, function () {
          // jsonData.elements.push({ name: this.name, value: this.value });
          if (this.name === "times_overdosed" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "sub_abuse_treatment" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "any_alcohol_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "barbiturates_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "benzodiazepines_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "cocaine_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "ghb_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "hallucinogens_psy_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "inhalants_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "ketamine_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "marijuana_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "metha_amph_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "if_opioids_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "tranquilizers_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else if (this.name === "other_days" && this.value.trim() === "") {
            jsonData.elements.push({ name: this.name, value: 0 });
          } else {
            jsonData.elements.push({ name: this.name, value: this.value });
          }
        });
        var json = JSON.stringify(jsonData);
        console.log(json);
        axios
          .post(global.save_url, json)
          .then((res) => {
            var responce = res.data;
            context.setState({
              // supplementaryInfoData: supplementaryInfoData_temp,
              unSaved: false,
            });
            console.log("post responce : ", res);
            sessionStorage.setItem(
              "success_msg",
              "Supplementary information saved successfully."
            );
            if ($('#clicked').val() === "proceed") {
              showSuccessToast('Supplementary information saved successfully.');
              const route = `${global.traversArray["supplementaryinfo"]}`;
              navigate(route);
              console.log("Navigating to route:", route);
            } else {
              Alert('success', 'Supplementary information saved successfully.');
            }
          })
          .catch(function (err) {
            console.log(err.message);
          }
          );
      },
    });
  }

  getTimeStamp(str) {
    var date = new Date(str),
      mnth = ("0" + (date.getMonth() + 1)).slice(-2),
      day = ("0" + date.getDate()).slice(-2);
    return [date.getFullYear(), mnth, day].join("-");
  }

  handleId = (e) => {
    $("#clicked").val(e.target.value);
    $("#clicked").val(e.currentTarget.value);
  };

  saveChanges() {
    //isko b dekhna hai
    $("#clicked").val("save");
    $("#supplementaryInfoForm").submit();
  }

  handleEvent(event, picker) {
    this.setState({
      startDate: picker.startDate,
      endDate: picker.endDate,
      unSaved: true,
    });
  }

  createSelectOptions() {
    if (validationObj !== "") {
      this.setState({
        eventCauseTraumaSelectOptions: selectOptionsGenerator(
          validationObj.options.events_caused_trauma,
          this.state.supplementaryInfoData.events_caused_trauma
        ),
        attemptedSuicideSelectOptions: selectOptionsGenerator(
          validationObj.options.attempted_suicide,
          this.state.supplementaryInfoData.attempted_suicide
        ),
        treatmentLocationSelectOptions: selectOptionsGenerator(
          validationObj.options.treatment_location,
          this.state.supplementaryInfoData.treatment_location
        ),
        stateAssessLocationSelectOptions: selectOptionsGenerator(
          validationObj.options.state_assess_loc,
          this.state.supplementaryInfoData.state_assess_loc
        ),
        anyAlcoholResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.any_alcohol_response,
          this.state.supplementaryInfoData.any_alcohol_response
        ),
        barbituratesResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.barbiturates_response,
          this.state.supplementaryInfoData.barbiturates_response
        ),
        benzodiazepinesReponseSelectOptions: selectOptionsGenerator(
          validationObj.options.benzodiazepines_reponse,
          this.state.supplementaryInfoData.benzodiazepines_reponse
        ),
        cocaineResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.cocaine_response,
          this.state.supplementaryInfoData.cocaine_response
        ),
        ghbResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.ghb_response,
          this.state.supplementaryInfoData.ghb_response
        ),
        hallucinogensPsyResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.hallucinogens_psy_response,
          this.state.supplementaryInfoData.hallucinogens_psy_response
        ),
        inhalantsResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.inhalants_response,
          this.state.supplementaryInfoData.inhalants_response
        ),
        ketamineResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.ketamine_response,
          this.state.supplementaryInfoData.ketamine_response
        ),
        marijuanaResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.marijuana_response,
          this.state.supplementaryInfoData.marijuana_response
        ),
        methaAmphResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.metha_amph_response,
          this.state.supplementaryInfoData.metha_amph_response
        ),
        opioidsResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.opioids_response,
          this.state.supplementaryInfoData.opioids_response
        ),
        ifOpioidsResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.if_opioids_response,
          this.state.supplementaryInfoData.if_opioids_response
        ),
        tranquilizersResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.tranquilizers_response,
          this.state.supplementaryInfoData.tranquilizers_response
        ),
        otherResponseSelectOptions: selectOptionsGenerator(
          validationObj.options.other_response,
          this.state.supplementaryInfoData.other_response
        ),
        everOverdosedSelectOptions: selectOptionsGenerator(
          validationObj.options.ever_overdosed,
          this.state.supplementaryInfoData.ever_overdosed
        ),
        stateAssessmentSelectOptions: selectOptionsGenerator(
          validationObj.options.state_assessment_completed,
          this.state.supplementaryInfoData.state_assessment_completed
        ),
        stateAssessmentWhereSelectOptions: selectOptionsGenerator(
          validationObj.options.state_assessment_where,
          this.state.supplementaryInfoData.state_assessment_where
        ),
        // Code Added By ashraf start
        anyAlcoholRouteOptions: selectOptionsGenerator(
          validationObj.options.any_alcohol_route,
          this.state.supplementaryInfoData.any_alcohol_route
        ),
        barBituratesRouteOption: selectOptionsGenerator(
          validationObj.options.barbiturates_route,
          this.state.supplementaryInfoData.barbiturates_route
        ),
        benzodiazepinesRouteOption: selectOptionsGenerator(
          validationObj.options.benzodiazepines_route,
          this.state.supplementaryInfoData.benzodiazepines_route
        ),
        cocaineRouteOption: selectOptionsGenerator(
          validationObj.options.cocaine_route,
          this.state.supplementaryInfoData.cocaine_route
        ),
        ghbRouteOption: selectOptionsGenerator(
          validationObj.options.ghb_route,
          this.state.supplementaryInfoData.ghb_route
        ),
        hallucinogensPsyRouteOption: selectOptionsGenerator(
          validationObj.options.hallucinogens_psy_route,
          this.state.supplementaryInfoData.hallucinogens_psy_route
        ),
        inhalantsRouteOption: selectOptionsGenerator(
          validationObj.options.inhalants_route,
          this.state.supplementaryInfoData.inhalants_route
        ),
        ketamineRouteOption: selectOptionsGenerator(
          validationObj.options.ketamine_route,
          this.state.supplementaryInfoData.ketamine_route
        ),
        marijuanaRouteOption: selectOptionsGenerator(
          validationObj.options.marijuana_route,
          this.state.supplementaryInfoData.marijuana_route
        ),
        methaAmphRouteOption: selectOptionsGenerator(
          validationObj.options.metha_amph_route,
          this.state.supplementaryInfoData.metha_amph_route
        ),
        opioidsRouteOption: selectOptionsGenerator(
          validationObj.options.opioids_route,
          this.state.supplementaryInfoData.opioids_route
        ),
        tranquilizersRouteOption: selectOptionsGenerator(
          validationObj.options.tranquilizers_route,
          this.state.supplementaryInfoData.tranquilizers_route
        ),
        otherRouteOption: selectOptionsGenerator(
          validationObj.options.other_route,
          this.state.supplementaryInfoData.other_route
        ),
        // Code Added By ashraf End

        //  ===================== Code Added By Kuldeep Singh  ========== ========  Start    ==========================================================================================================

        scheduledDateStateAssessmentSelectOptions: selectOptionsGenerator(validationObj.options.scheduled_date_state_assessment, this.state.supplementaryInfoData.scheduled_date_state_assessment),

        //  ===================== Code Added By Kuldeep Singh  ========== ========  End    ============================================================================================================
      });
    }
  }


  // shouldNavigateFunction = () => {
  //   var navigation = false;
  //   return
  // }

  handleChangeLastAssessDate = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      supplementaryInfoData: {
        ...prevState.supplementaryInfoData,
        last_assess_date: date,
      },
      unSaved: true,
    }));
  };

  handleScheduledDate = (date) => {
    this.setState((prevState) => ({
      ...prevState,
      supplementaryInfoData: {
        ...prevState.supplementaryInfoData,
        scheduled_date: date,
      },
      unSaved: true,
    }));
  };

  closeViewHistoryModal = (status) => {

    this.setState({ viewHistoryModal: status })

  }



  handleViewHistoryDetails = (event) => {
    this.setState({
      viewHistoryModal: true
    });

    if (sessionStorage.getItem('clientId') !== '') {

      axios.get(global.restApiURL + 'webservice/rest/object-list?apikey=' + sessionStorage.getItem('__TOKEN__') + '&limit=1&objectClass=SupplementaryInformation&q={"client_id":"' + sessionStorage.getItem('clientId') + '"}')

        .then(res => {

          const responce = res.data.data; // get the data array instead of object

          if (responce[0] !== undefined || responce[0].id !== '') {

            axios.get(global.restApiURL + 'webservice/rest/object/id/' + responce[0].id + '?apikey=' + sessionStorage.getItem('__TOKEN__'))

              .then(res => {

                sessionStorage.setItem('responceId', res.data.data.id);

                this.setState({ object_id: res.data.data.id });

                const responce = res.data.data.elements;

                // this.datamapper(responce);

                // let parsedClientsInfo = formObjDataMapper2(responce, this.getModal());

                // this.setState({ initialClientsInfo: parsedClientsInfo });

                var rules = [];

                var regex_var = {};

                var realArray = this.state.regex;

                Object.entries(realArray).map(function ([field, reg_ex], i) {

                  if (reg_ex !== '' && reg_ex !== undefined) {

                    regex_var[field] = '/' + reg_ex + '/';

                    $.validator.addMethod(regex_var[field], function (value, element, parameter) {

                      return value.match(regex_var[field]);

                    }, '');

                    rules[field] = { [regex_var[field]]: true };

                  }

                });

                // initialFormValues = $('#intakebasicinfoform').serialize();

              })

              .catch(error => { // log request error and prevent access to undefined state

                this.setState({ loading: false, error: true });

                if (error.response) {

                  // Request made and server responded

                  // alert(error.response.data.msg);

                  console.log(error.response.data.msg)

                  if (error.response.status === 401 || error.response.status === 403) {

                    window.location.href = global.domain + global.traversArray['out'];

                  }

                } else if (error.request) {

                  // The request was made but no response was received

                  // alert(error.request);

                  console.log(error.request)

                } else {

                  // Something happened in setting up the request that triggered an Error

                  // alert(error.message);

                  console.log(error.message)

                }

              }

              )

          }

        })

        .catch(error => { // log request error and prevent access to undefined state

          this.setState({ loading: false, error: true });

          if (error.response) {

            // Request made and server responded

            // alert(error.response.data.msg);

            console.log(error.response.data.msg)

            if (error.response.status === 401 || error.response.status === 403) {

              window.location.href = global.domain + global.traversArray['out'];

            }

          } else if (error.request) {

            // The request was made but no response was received

            // alert(error.request);

            console.log(error.request)

          } else {
            // alert(error.message);

            console.log(error.message)

          }

        })

    }

  }

  handleSave = (name) => (event) => {
    $(".saveButton1").attr("disabled", false);
    $(".saveButtonProceed1").attr("disabled", false);
    $(".saveButton2").attr("disabled", false);
    $(".saveButtonProceed2").attr("disabled", false);
    var supplementaryInfoData = this.state.supplementaryInfoData;
    supplementaryInfoData[event.target.name] = event.target.value;
    if (name !== "") {
      if (event.target.value !== "Yes") {
        supplementaryInfoData[name] = 0;
      } else if (
        event.target.value === "Other" ||
        event.target.value === "Other (Please Specify Below)"
      ) {
        supplementaryInfoData[name] = "";
      }
    }
    if (event.target.name === "opioids_response" && event.target.value !== "Yes") {
      supplementaryInfoData["if_opioids_response"] = "";
      supplementaryInfoData["if_opioids_days"] = "";
      supplementaryInfoData["scheduled_date"] = "";
    } else if (event.target.name === "other_response" && event.target.value !== "Yes") {
      supplementaryInfoData["other_please_specify"] = "";
      supplementaryInfoData["other_days"] = "";
      supplementaryInfoData["scheduled_date"] = "";
    }
    this.setState({ supplementaryInfoData, unSaved: true });
    if (event.target.name === "scheduled_date_state_assessment") {
      context.state.supplementaryInfoData.scheduled_date = "";
    }
  };

  // handleTimeronBlur(date, field) {
  //   date = date ? date : context.state.supplementaryInfoData[field];
  //   let supplementaryInfoData_temp = this.state.supplementaryInfoData;
  //   const dateFormat = 'MM/DD/YYYY';
  //   let formattedDate = "";
  //   if (typeof date === 'string' && date !== "") {
  //     if (moment(date, dateFormat, true).isValid()) {
  //       formattedDate = moment(date).format(dateFormat);
  //     } else {
  //       alert('Invalid Date');
  //       formattedDate = "";
  //     }
  //   } else if (typeof date === 'object' && date !== null) {
  //     if (moment(date._d, dateFormat, true).isValid()) {
  //       formattedDate = moment(date._d).format(dateFormat);
  //     } else {
  //       alert('Invalid Date');
  //       formattedDate = "";
  //     }
  //   }
  //   supplementaryInfoData_temp[field] = formattedDate;
  //   // supplementaryInfoData_temp[field] = date === "" ? "" :moment.utc(date).format('MM/DD/YYYY')=== "01/01/1970"?"":moment.utc(date).format('MM/DD/YYYY');
  //   // //supplementaryInfoData_temp[field] = date === "" ? {placeholder:'MM/DD/YYYY'} : typeof date === 'string' ? moment.utc(date).format('MM/DD/YYYY') : "";
  //   context.setState({ supplementaryInfoData: supplementaryInfoData_temp, unSaved: true, });
  // }


  handleTimeronBlur(date, field) {
    date = context.state.supplementaryInfoData[field];
    const formattedDate = moment(date, "MM/DD/YYYY", true);
    let supplementaryInfoData_temp = this.state.supplementaryInfoData;
    supplementaryInfoData_temp[field] = date === "" ? "" : moment.utc(date).format('MM/DD/YYYY');
    // supplementaryInfoData_temp[field] = date === "" ? {placeholder:'MM/DD/YYYY'} : typeof date === 'string' ? moment.utc(date).format('MM/DD/YYYY') : "";
    // supplementaryInfoData_temp[field] = date === "" ? 'MM/DD/YYYY' : typeof date === 'string' ? moment.utc(date).format('MM/DD/YYYY') : "";
    if (!formattedDate.isValid() && date !== "") {
      if (field === "last_assess_date") {
        alert("Invalid Date");
        this.setState((prevState) => ({ ...prevState, supplementaryInfoData: { ...prevState.supplementaryInfoData, last_assess_date: "", unSaved: true } }));
      }
      return false;
    }
    context.setState({ supplementaryInfoData: supplementaryInfoData_temp, unSaved: true });
  };

  handleTimer(date, field) {
    let supplementaryInfoData_temp = this.state.supplementaryInfoData;
    supplementaryInfoData_temp[field] =
      date._d !== undefined ? moment(date._d).format("MM/DD/YYYY") : date;
    context.setState({
      supplementaryInfoData: supplementaryInfoData_temp,
      unSaved: true,
    });
  }

  validDate(current, selectedDate) {
    return current.isBefore(moment());
  }

  render() {
    sessionStorage.setItem("formname", "Supplementary Information");
    let start = this.state.startDate.format("MM-DD-YYYY HH:mm:ss");
    let end = this.state.endDate.format("MM-DD-YYYY HH:mm:ss");
    let label = start + " - " + end;
    if (start === end) {
      label = start;
    }

    $(document).ready(function () {
      $("#supplementaryInfoForm").validate({
        rules: {},
        errorElement: "span",
        errorPlacement: function (error, element) {
          error.addClass("invalid-feedback");
          element.closest(".form-group").append(error);
          element.closest("td").append(error);
        },
        highlight: function (element, errorClass, validClass) {
          $(element).addClass("is-invalid");
        },
        unhighlight: function (element, errorClass, validClass) {
          $(element).removeClass("is-invalid");
        },
      });
    });

    return (
      <div className="hold-transition sidebar-mini text-sm">
        { /*     Start Kuldeep Singh     This was using in dev server when Routing was reloading but now instead of <ConfirmBeforeLeave /> we are using <sidenav /> we are using sidenav By Kuldeep Singh            */}
        {/* <ConfirmBeforeLeave onRef={(ref) => (this.childForm = ref)} unSaved={context.state.unSaved} onSaveRequest={() => context.saveChanges()} /> */}
        { /*          This was using in dev server when Routing was reloading but now instead of <ConfirmBeforeLeave /> we are using <sidenav /> By Kuldeep Singh     End Kuldeep Singh       */}
        <ReactTooltip place="right" type="dark" effect="solid" />
        <div className="wrapper">
          <AppTopNav />
          <div className="content-wrapper">
            <Patientinfoheader
              clientId={sessionStorage.getItem("clientId")}
              dob={encryptStorage.getItem("dob")}
              email={encryptStorage.getItem("email")}
              phone={encryptStorage.getItem("phone")}
              peer_support_specialist={encryptStorage.getItem("peer_support_specialist")}
              next_followup_date={encryptStorage.getItem("month_1_followup_date")}
              intake_date={encryptStorage.getItem("intake_date")}
              initiated_by={encryptStorage.getItem("last_updated_by")}
              formname={sessionStorage.getItem("formname")}
              client_name={encryptStorage.getItem("client_first_name") + " " + encryptStorage.getItem("client_middle_initial") + " " + encryptStorage.getItem("client_last_name")}
            />
            <section className="content">
              <div className="row">
                <Sidenav onSaveRequest={() => context.saveChanges()} unSaved={context.state.unSaved} shouldNavigate={() => { return true }} />
                <fieldset disabled={!can_edit("SupplementaryInformation")} className="container-fluid col-md-9 col-lg-10" >
                  <form action="javascript:void(0);" method="post" id="supplementaryInfoForm" >
                    <div className="card card-default">
                      <div className="card-header">
                        <p className="lead float-left"> <b className="text-green">Supplementary Info</b>{" "} </p>
                        {can_view_version('SupplementaryInformation') ? (<a data-toggle="modal" data-target="#modal-history" className="btn btn-default float-right" onClick={() => this.handleViewHistoryDetails()}> View History</a>) : ''}
                        <button type="submit" className="btn btn-primary float-right btnmargin saveButtonProceed1" value="proceed" onClick={this.handleId}><img src="dist/img/save-proceed_icon.png" alt="" />{" "}Save &amp; Proceed{" "} </button>
                        <button type="submit" id="saveButton" value="save" className="btn btn-success float-right btnmargin saveButton1" onClick={this.handleId} > {" "} <i className="fas fa-save"></i> Save </button>
                        <input onChange={this.handleSave("")} type="hidden" id="clicked" value="" readOnly />
                        <input onChange={this.handleSave("")} type="text" id="client_id" name="client_id" style={{ display: "none" }} defaultValue={sessionStorage.getItem("clientId")} />
                      </div>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-lg-8  col-md-6 form-group">
                            <LabelField title={this.state.labelText.ever_overdosed} mandatory={this.state.required.ever_overdosed} name="ever_overdosed" tooltip={this.state.tooltips.ever_overdosed} id="ever_overdosed" />
                          </div>
                          <div className="col-lg-4  col-md-6 form-group">
                            <select className="custom-select" id="ever_overdosed" name="ever_overdosed" onChange={this.handleSave("times_overdosed")} value={this.state.supplementaryInfoData.ever_overdosed}>
                              {this.state.everOverdosedSelectOptions}
                            </select>
                          </div>
                          {this.state.supplementaryInfoData.ever_overdosed === "Yes" ? (
                            <>
                              <div className="col-lg-8  col-md-6 form-group">
                                <LabelField title={this.state.labelText.times_overdosed} mandatory={this.state.required.times_overdosed} name="times_overdosed" tooltip={this.state.tooltips.times_overdosed} id="times_overdosed" />
                              </div>
                              <div className="col-lg-4  col-md-6 form-group">
                                <input required={this.state.required.times_overdosed} type="number" min="0" max="36500" name="times_overdosed" className="form-control" id="times_overdosed" value={this.state.supplementaryInfoData.times_overdosed} onChange={this.handleSave("")} />
                              </div>
                            </>
                          ) : (
                            <input type="hidden" name="times_overdosed" value="0" />
                          )}
                          <div className="col-lg-8  col-md-6 form-group">
                            <LabelField title={this.state.labelText.events_caused_trauma} mandatory={this.state.required.events_caused_trauma} name="events_caused_trauma" tooltip={this.state.tooltips.events_caused_trauma} id="events_caused_trauma" />
                          </div>
                          <div className="col-lg-4  col-md-6 form-group">
                            <select onChange={this.handleSave("")} id="events_caused_trauma" name="events_caused_trauma" className="custom-select" value={this.state.supplementaryInfoData.events_caused_trauma} required={this.state.required.events_caused_trauma}>
                              {this.state.eventCauseTraumaSelectOptions}
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8  col-md-6 form-group">
                            <LabelField title={this.state.labelText.attempted_suicide} mandatory={this.state.required.attempted_suicide} name="attempted_suicide" tooltip={this.state.tooltips.attempted_suicide} id="attempted_suicide" />
                          </div>
                          <div className="col-lg-4  col-md-6 form-group">
                            <select onChange={this.handleSave("")} id="attempted_suicide" name="attempted_suicide" className="custom-select" value={this.state.supplementaryInfoData.attempted_suicide} required={this.state.required.attempted_suicide} >
                              {this.state.attemptedSuicideSelectOptions}
                            </select>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8  col-md-6 form-group">
                            <LabelField title={this.state.labelText.sub_abuse_treatment} mandatory={this.state.required.sub_abuse_treatment} name="sub_abuse_treatment" tooltip={this.state.tooltips.sub_abuse_treatment} id="sub_abuse_treatment" />
                          </div>
                          <div className="col-lg-4  col-md-6 form-group">
                            <input onChange={this.handleSave("")} placeholder="Abuse Treatment" id="sub_abuse_treatment" name="sub_abuse_treatment" type="number" max="36500" min="0" className="form-control" value={this.state.supplementaryInfoData.sub_abuse_treatment} required={this.state.required.sub_abuse_treatment} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8  col-md-6 form-group">
                            <LabelField
                              title={this.state.labelText.treatment_location}
                              mandatory={this.state.required.treatment_location}
                              name="treatment_location"
                              tooltip={this.state.tooltips.treatment_location}
                              id="treatment_location"
                              className="marginleft"
                            />
                          </div>
                          <div className="col-lg-4  col-md-6 ">
                            <select onChange={this.handleSave("treatment_loc_other  ")} id="treatment_location" name="treatment_location" className="custom-select form-group my_agency" required={this.state.required.treatment_location} value={this.state.supplementaryInfoData.treatment_location}>
                              {this.state.treatmentLocationSelectOptions}
                            </select>
                            {this.state.supplementaryInfoData.treatment_location === "Other" ? (
                              <input
                                onChange={this.handleSave("")}
                                id="treatment_loc_other"
                                name="treatment_loc_other"
                                type="text"
                                className="form-control form-group"
                                placeholder={this.state.labelText.treatment_loc_other}
                                value={this.state.supplementaryInfoData.treatment_loc_other}
                                required={this.state.required.treatment_loc_other} maxLength={this.state.required.treatment_loc_other}
                              />
                            ) : (
                              <input
                                id="treatment_loc_other"
                                name="treatment_loc_other"
                                type="hidden"
                                className="form-control"
                                placeholder={this.state.labelText.treatment_loc_other}
                                value=""
                              />
                            )}
                          </div>
                        </div>
                        {this.state.supplementaryInfoData.state_assess_loc == this.state.supplementaryInfoData.state_assessment_where ? (" ") :
                          <div className="row">
                            <div className="col-lg-8  col-md-6 form-group">
                              <LabelField
                                title={this.state.labelText.state_assess_loc}
                                mandatory={this.state.required.state_assess_loc}
                                name="state_assess_loc"
                                tooltip={this.state.tooltips.state_assess_loc}
                                id="state_assess_loc"
                                className="marginleft"
                              />
                            </div>
                            <div className="col-lg-4  col-md-6 ">
                              <select
                                onChange={this.handleSave("state_assess_loc_other")}
                                id="state_assess_loc"
                                name="state_assess_loc"
                                className="custom-select form-group my_agency"
                                required={this.state.required.state_assess_loc}
                                value={this.state.supplementaryInfoData.state_assess_loc}
                              >
                                {this.state.stateAssessLocationSelectOptions}
                              </select>
                              {this.state.supplementaryInfoData
                                .state_assess_loc === "Other" ? (
                                <input
                                  onChange={this.handleSave("")}
                                  id="state_assess_loc_other"
                                  name="state_assess_loc_other"
                                  type="text"
                                  className="form-control form-group"
                                  placeholder={this.state.labelText.state_assess_loc_other}
                                  value={this.state.supplementaryInfoData.state_assess_loc_other}
                                  required={this.state.required.state_assess_loc_other}
                                  maxLength={this.state.required.state_assess_loc_other}
                                />
                              ) : (
                                <input
                                  id="state_assess_loc_other"
                                  name="state_assess_loc_other"
                                  type="hidden"
                                  className="form-control"
                                  placeholder={this.state.labelText.state_assess_loc_other}
                                  value=""
                                />
                              )}
                            </div>
                          </div>
                        }
                        <div className="row">
                          <div className="col-lg-8  col-md-6 form-group">
                            <LabelField
                              title={this.state.labelText.last_assess_date}
                              mandatory={this.state.required.last_assess_date}
                              name="last_assess_date"
                              tooltip={this.state.tooltips.last_assess_date}
                              id="last_assess_date"
                              className="marginleft"
                            />{" "}
                          </div>
                          <div className="col-lg-4  col-md-6 form-group">
                            <div className="input-group-append" style={{ height: "38px", float: "left", marginRight: "-3px", }} >
                              <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                            </div>
                            <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }}  >
                              <span style={{ width: "100%" }}>
                                <DateTime
                                  onBlur={(date) => this.handleTimeronBlur(date, 'last_assess_date')}
                                  onChange={(date) => this.handleTimer(date, 'last_assess_date')}
                                  inputProps={{
                                    value: typeof context.state.supplementaryInfoData.last_assess_date === 'string' ? context.state.supplementaryInfoData.last_assess_date : moment.utc(context.state.supplementaryInfoData.last_assess_date).format('MM/DD/YYYY') === "01/01/1970" ? "" : moment.utc(context.state.supplementaryInfoData.last_assess_date).format('MM/DD/YYYY'),
                                    required: this.state.required.last_assess_date,
                                    name: 'last_assess_date',
                                    placeholder: 'MM/DD/YYYY'
                                  }}
                                  defaultValue={moment.utc().format('MM/DD/YYYY')}
                                  dateFormat="MM/DD/YYYY" timeFormat={false}
                                  isValidDate={this.validDate}
                                  input={true}
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-lg-8  col-md-6 form-group">
                            <LabelField
                              title={this.state.labelText.state_assessment_completed}
                              name="state_assessment_completed"
                              tooltip={this.state.tooltips.state_assessment_completed}
                              id="state_assessment_completed"
                            />
                          </div>
                          <div className="col-lg-4  col-md-6 form-group">
                            <select
                              className="custom-select"
                              id="state_assessment_completed"
                              name="state_assessment_completed"
                              onChange={this.handleSave("times_overdosed")}
                              value={this.state.supplementaryInfoData.state_assessment_completed}
                            >
                              {this.state.stateAssessmentSelectOptions}
                            </select>
                          </div>
                          {this.state.supplementaryInfoData.state_assessment_completed === "Yes" ? (
                            <>
                              <div className="col-lg-8  col-md-6 form-group">
                                <LabelField
                                  title={this.state.labelText.state_assessment_where}
                                  name="state_assessment_where"
                                  tooltip={this.state.tooltips.state_assessment_where}
                                  id="state_assessment_where"
                                />
                              </div>
                              <div className="col-lg-4  col-md-6 form-group">
                                <select
                                  className="custom-select"
                                  id="state_assessment_where"
                                  name="state_assessment_where"
                                  onChange={this.handleSave("state_assessment")}
                                  value={this.state.supplementaryInfoData.state_assessment_where}
                                >
                                  {this.state.stateAssessmentWhereSelectOptions}
                                </select>
                                {this.state.supplementaryInfoData.state_assessment_where === "Other" ?
                                  <input onChange={this.handleSave('')} id="other_state_assessment_where" name="other_state_assessment_where" type="text" className="form-control form-group mt-3" placeholder={this.state.labelText.other_state_assessment_where} value={this.state.supplementaryInfoData.other_state_assessment_where} /> : ""
                                }
                              </div>
                            </>
                          ) : (
                            <div>
                              <input
                                type="hidden"
                                name="state_assessment_where"
                                value=""
                              />
                              <input
                                type="hidden"
                                name="scheduled_date"
                                value=""
                              />
                            </div>
                          )}
                        </div>

                        {/* ============================================= Code added By Kuldeep Singh ============== Start ================================================================ */}
                        <div className='row'>
                          <div className="col-lg-8  col-md-6 form-group">
                            <LabelField title={this.state.labelText.scheduled_date_state_assessment} name="scheduled_date_state_assessment" tooltip={this.state.tooltips.scheduled_date_state_assessment} id="scheduled_date_state_assessment" />
                          </div>
                          <div className="col-lg-4  col-md-6 form-group">
                            <select className="custom-select" id="scheduled_date_state_assessment" name="scheduled_date_state_assessment" value={this.state.scheduled_date_state_assessment} onChange={this.handleSave('scheduled_date_state_assessment')}>
                              {this.state.scheduledDateStateAssessmentSelectOptions}
                            </select>
                          </div>
                        </div>

                        {this.state.supplementaryInfoData
                          .scheduled_date_state_assessment == "Yes" ? (
                          <div className="row">
                            <div className="col-lg-8  col-md-6 form-group">
                              <LabelField
                                title={this.state.labelText.scheduled_date}
                                name="scheduled_date"
                                tooltip={this.state.tooltips.scheduled_date}
                                id="scheduled_date"
                              />
                            </div>
                            <div className="col-lg-4  col-md-6 form-group">
                              <div className="input-group-append" style={{ height: "38px", float: "left", marginRight: "-3px", }} >
                                <div className="input-group-text"><i className="far fa-calendar-alt"></i></div>
                              </div>
                              <div className="input-group date timepicker" style={{ width: "calc(100% - 41px)" }} >
                                <span style={{ width: "100%" }}>
                                  <DateTime
                                    onBlur={(date) => this.handleTimeronBlur(date, 'scheduled_date')}
                                    onChange={(date) => this.handleTimer(date, 'scheduled_date')}
                                    inputProps={{ value: typeof context.state.supplementaryInfoData.scheduled_date === 'string' ? context.state.supplementaryInfoData.scheduled_date : moment.utc(context.state.supplementaryInfoData.scheduled_date).format('MM/DD/YYYY') === "01/01/1970" ? "" : moment.utc(context.state.supplementaryInfoData.scheduled_date).format('MM/DD/YYYY'), required: this.state.required.scheduled_date, name: 'scheduled_date', placeholder: 'MM/DD/YYYY' }}
                                    defaultValue={moment.utc().format('MM/DD/YYYY')}
                                    dateFormat="MM/DD/YYYY"
                                    timeFormat={false}
                                    isValidDate={this.validDate}
                                    input={true}
                                  />
                                </span>
                              </div>
                            </div>
                          </div>
                        ) : (
                          ""
                        )}

                        {/* ============================================= Code added By Kuldeep Singh ============== End ================================================================ */}

                        <div className="row">
                          <div className="col-lg-12 col-md-12">
                            <h6> Indicate whether you have used any of the following substances in the past 30 days </h6>
                            <div className="table-responsive p-0">
                              <table className="table table-bordered table-striped">
                                <thead>
                                  <tr>
                                    <th>Substances used in the past 30 days</th>
                                    <th>Route</th>
                                    <th>How many total days out of the last 30? (0 means none)</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.any_alcohol_response}
                                            mandatory={this.state.required.any_alcohol_response}
                                            name="any_alcohol_response"
                                            tooltip={this.state.tooltips.any_alcohol_response}
                                            id="any_alcohol_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("any_alcohol_days")}
                                            id="any_alcohol_response"
                                            name="any_alcohol_response"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.any_alcohol_response}
                                            required={this.state.required.any_alcohol_response}
                                          >
                                            {this.state.anyAlcoholResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("any_alcohol_days")}
                                            id="any_alcohol_route"
                                            name="any_alcohol_route"
                                            className="custom-select"
                                            required={this.state.required.any_alcohol_route}
                                            value={this.state.supplementaryInfoData.any_alcohol_route}
                                          >
                                            {this.state.anyAlcoholRouteOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="any_alcohol_days"
                                        name="any_alcohol_days"
                                        readOnly={this.state.supplementaryInfoData.any_alcohol_response !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.any_alcohol_response !== "Yes" ? 0 : this.state.supplementaryInfoData.any_alcohol_days}
                                        required={this.state.required.any_alcohol_response}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.barbiturates_response}
                                            mandatory={this.state.required.barbiturates_response}
                                            name="barbiturates_response"
                                            tooltip={this.state.tooltips.barbiturates_response}
                                            id="barbiturates_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("barbiturates_days")}
                                            id="barbiturates_response"
                                            name="barbiturates_response"
                                            className="custom-select"
                                            required={this.state.required.barbiturates_response}
                                            value={this.state.supplementaryInfoData.barbiturates_response}
                                          >
                                            {this.state.barbituratesResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    {/* ////////////////////////// */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave()}
                                            id="barbiturates_route"
                                            name="barbiturates_route"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.barbiturates_route}
                                            required={this.state.required.barbiturates_route}
                                          >
                                            {this.state.anyAlcoholRouteOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="barbiturates_days"
                                        name="barbiturates_days"
                                        readOnly={this.state.supplementaryInfoData.barbiturates_response !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.barbiturates_response !== "Yes" ? 0 : this.state.supplementaryInfoData.barbiturates_days}
                                        required={this.state.required.barbiturates_response}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.benzodiazepines_reponse}
                                            mandatory={this.state.required.benzodiazepines_reponse}
                                            name="benzodiazepines_reponse"
                                            tooltip={this.state.tooltips.benzodiazepines_reponse}
                                            id="benzodiazepines_reponse"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("benzodiazepines_days")}
                                            id="benzodiazepines_reponse"
                                            name="benzodiazepines_reponse"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.benzodiazepines_reponse}
                                            required={this.state.required.benzodiazepines_reponse}
                                          >
                                            {this.state.benzodiazepinesReponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="benzodiazepines_route"
                                            name="benzodiazepines_route"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.benzodiazepines_route}
                                            required={this.state.required.benzodiazepines_route}
                                          >
                                            {this.state.benzodiazepinesRouteOption}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="benzodiazepines_days"
                                        name="benzodiazepines_days"
                                        readOnly={this.state.supplementaryInfoData.benzodiazepines_reponse !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.benzodiazepines_reponse !== "Yes" ? 0 : this.state.supplementaryInfoData.benzodiazepines_days}
                                        required={this.state.required.benzodiazepines_days}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.cocaine_response}
                                            mandatory={this.state.required.cocaine_response}
                                            name="cocaine_response"
                                            tooltip={this.state.tooltips.cocaine_response}
                                            id="cocaine_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("cocaine_days")}
                                            id="cocaine_response"
                                            name="cocaine_response"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.cocaine_response}
                                            required={this.state.required.cocaine_response}
                                          >
                                            {this.state.cocaineResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="cocaine_route"
                                            value={this.state.supplementaryInfoData.cocaine_route}
                                            name="cocaine_route"
                                            className="custom-select"
                                            required={this.state.required.cocaine_route}
                                          >
                                            {this.state.cocaineRouteOption}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="cocaine_days"
                                        name="cocaine_days"
                                        readOnly={this.state.supplementaryInfoData.cocaine_response !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.cocaine_response !== "Yes" ? 0 : this.state.supplementaryInfoData.cocaine_days}
                                        required={this.state.required.cocaine_days}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.ghb_response}
                                            mandatory={this.state.required.ghb_response}
                                            name="ghb_response"
                                            tooltip={this.state.tooltips.ghb_response}
                                            id="ghb_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("ghb_days")}
                                            id="ghb_response"
                                            name="ghb_response"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.ghb_response}
                                            required={this.state.required.ghb_response}
                                          >
                                            {this.state.ghbResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="ghb_route"
                                            name="ghb_route"
                                            value={this.state.supplementaryInfoData.ghb_route}
                                            className="custom-select"
                                            required={this.state.required.ghb_route}
                                          >
                                            {this.state.ghbRouteOption}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="ghb_days"
                                        name="ghb_days"
                                        readOnly={this.state.supplementaryInfoData.ghb_response !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.ghb_response !== "Yes" ? 0 : this.state.supplementaryInfoData.ghb_days}
                                        required={this.state.required.ghb_days}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.hallucinogens_psy_response}
                                            mandatory={this.state.required.hallucinogens_psy_response}
                                            name="hallucinogens_psy_response"
                                            tooltip={this.state.tooltips.hallucinogens_psy_response}
                                            id="hallucinogens_psy_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("hallucinogens_psy_days")}
                                            id="hallucinogens_psy_response"
                                            name="hallucinogens_psy_response"
                                            value={this.state.supplementaryInfoData.hallucinogens_psy_response}
                                            className="custom-select"
                                            required={this.state.required.hallucinogens_psy_response}
                                          >
                                            {this.state.hallucinogensPsyResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="hallucinogens_psy_route"
                                            name="hallucinogens_psy_route"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.hallucinogens_psy_route}
                                            required={this.state.required.hallucinogens_psy_route}
                                          >
                                            {this.state.hallucinogensPsyRouteOption}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="hallucinogens_psy_days"
                                        name="hallucinogens_psy_days"
                                        readOnly={this.state.supplementaryInfoData.hallucinogens_psy_response !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.hallucinogens_psy_response !== "Yes" ? 0 : this.state.supplementaryInfoData.hallucinogens_psy_days}
                                        required={this.state.required.hallucinogens_psy_days}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.inhalants_response}
                                            mandatory={this.state.required.inhalants_response}
                                            name="inhalants_response"
                                            tooltip={this.state.tooltips.inhalants_response}
                                            id="inhalants_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("inhalants_days")}
                                            id="inhalants_response"
                                            name="inhalants_response"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.inhalants_response}
                                            required={this.state.required.inhalants_response}
                                          >
                                            {this.state.inhalantsResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("")}
                                            value={this.state.supplementaryInfoData.inhalants_route}
                                            id="inhalants_route"
                                            name="inhalants_route"
                                            className="custom-select"
                                            required={this.state.required.inhalants_route}
                                          >
                                            {this.state.inhalantsRouteOption}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="inhalants_days"
                                        name="inhalants_days"
                                        readOnly={this.state.supplementaryInfoData.inhalants_response !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.inhalants_response !== "Yes" ? 0 : this.state.supplementaryInfoData.inhalants_days}
                                        required={this.state.required.inhalants_days}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.ketamine_response}
                                            mandatory={this.state.required.ketamine_response}
                                            name="ketamine_response"
                                            tooltip={this.state.tooltips.ketamine_response}
                                            id="ketamine_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("ketamine_days")}
                                            id="ketamine_response"
                                            name="ketamine_response"
                                            value={this.state.supplementaryInfoData.ketamine_response}
                                            className="custom-select"
                                            required={this.state.required.ketamine_response}
                                          >
                                            {this.state.ketamineResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="ketamine_route"
                                            name="ketamine_route"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.ketamine_route}
                                            required={this.state.required.ketamine_route}
                                          >
                                            {this.state.ketamineRouteOption}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="ketamine_days"
                                        name="ketamine_days"
                                        readOnly={this.state.supplementaryInfoData.ketamine_response !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.ketamine_response !== "Yes" ? 0 : this.state.supplementaryInfoData.ketamine_days}
                                        required={this.state.required.ketamine_days}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.marijuana_response}
                                            mandatory={this.state.required.marijuana_response}
                                            name="marijuana_response"
                                            tooltip={this.state.tooltips.marijuana_response}
                                            id="marijuana_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("marijuana_days")}
                                            id="marijuana_response"
                                            name="marijuana_response"
                                            value={this.state.supplementaryInfoData.marijuana_response}
                                            className="custom-select"
                                            required={this.state.required.marijuana_response}
                                          >
                                            {this.state.marijuanaResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="marijuana_route"
                                            name="marijuana_route"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.marijuana_route}
                                            required={this.state.required.marijuana_route}
                                          >
                                            {this.state.marijuanaRouteOption}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="marijuana_days"
                                        name="marijuana_days"
                                        readOnly={this.state.supplementaryInfoData.marijuana_response !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.marijuana_response !== "Yes" ? 0 : this.state.supplementaryInfoData.marijuana_days}
                                        required={this.state.required.marijuana_days}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.metha_amph_response}
                                            mandatory={this.state.required.metha_amph_response}
                                            name="metha_amph_response"
                                            tooltip={this.state.tooltips.metha_amph_response}
                                            id="metha_amph_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("metha_amph_days")}
                                            id="metha_amph_response"
                                            name="metha_amph_response"
                                            value={this.state.supplementaryInfoData.metha_amph_response}
                                            className="custom-select"
                                            required={this.state.required.metha_amph_response}
                                          >
                                            {this.state.methaAmphResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="metha_amph_route"
                                            name="metha_amph_route"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.metha_amph_route}
                                            required={this.state.required.metha_amph_route}
                                          >
                                            {this.state.methaAmphRouteOption}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="metha_amph_days"
                                        name="metha_amph_days"
                                        readOnly={this.state.supplementaryInfoData.metha_amph_response !== "Yes"}
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.metha_amph_response !== "Yes" ? 0 : this.state.supplementaryInfoData.metha_amph_days}
                                        required={this.state.required.metha_amph_days}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.opioids_response}
                                            mandatory={this.state.required.opioids_response}
                                            name="opioids_response"
                                            tooltip={this.state.tooltips.opioids_response}
                                            id="opioids_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="opioids_response"
                                            name="opioids_response"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.opioids_response}
                                            required={this.state.required.opioids_response}
                                          >
                                            {this.state.opioidsResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                  {this.state.supplementaryInfoData
                                    .opioids_response === "Yes" ? (
                                    <tr>
                                      <td colspan="1">
                                        <div className="row">
                                          <div className="col-lg-8  col-md-8">
                                            <LabelField
                                              title={this.state.labelText.if_opioids_response}
                                              mandatory={this.state.required.if_opioids_response}
                                              name="if_opioids_response"
                                              tooltip={this.state.tooltips.if_opioids_response}
                                              id="if_opioids_response"
                                            />
                                          </div>
                                          <div className="col-lg-4  col-md-4">
                                            <select
                                              onChange={this.handleSave("")}
                                              id="if_opioids_response"
                                              name="if_opioids_response"
                                              value={this.state.supplementaryInfoData.if_opioids_response}
                                              className="custom-select"
                                              required={this.state.required.if_opioids_response}
                                            >
                                              {this.state.ifOpioidsResponseSelectOptions}
                                            </select>
                                          </div>
                                          <div className="col-lg-4 col-md-3">
                                            {/* <input onChange={this.handleSave('')} id="if_opioids_specify" name="if_opioids_specify" style={{display:"none"}} type="text" className="form-control" value={this.state.supplementaryInfoData.if_opioids_specify} placeholder={this.state.labelText.if_opioids_specify} /> */}
                                          </div>
                                        </div>
                                      </td>
                                      {/*Code By ashraf start  */}
                                      <td>
                                        <div className="row">
                                          <div className="col-lg-12 col-md-12">
                                            <select
                                              onChange={this.handleSave("")}
                                              id="opioids_route"
                                              name="opioids_route"
                                              value={this.state.supplementaryInfoData.opioids_route}
                                              className="custom-select"
                                              required={this.state.required.opioids_route}
                                            >
                                              {this.state.opioidsRouteOption}
                                            </select>
                                          </div>
                                        </div>
                                      </td>
                                      {/* Code by ashraf end */}
                                      <td>
                                        <input
                                          onChange={this.handleSave("")}
                                          id="if_opioids_days"
                                          readOnly={this.state.supplementaryInfoData.if_opioids_response === ""}
                                          name="if_opioids_days"
                                          type="number"
                                          max="30"
                                          data-msg="Please enter a value between 0 and 30 days"
                                          min="0"
                                          className="form-control"
                                          value={this.state.supplementaryInfoData.if_opioids_response === "" ? 0 : this.state.supplementaryInfoData.if_opioids_days}
                                          required={this.state.required.if_opioids_days}
                                        />
                                      </td>
                                    </tr>
                                  ) : (
                                    <div>
                                      <input id="if_opioids_days" name="if_opioids_days" type="hidden" value="0" />
                                      <input id="if_opioids_response" name="if_opioids_response" type="hidden" value="" />
                                    </div>
                                  )}
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.tranquilizers_response}
                                            mandatory={this.state.required.tranquilizers_response}
                                            name="tranquilizers_response"
                                            tooltip={this.state.tooltips.tranquilizers_response}
                                            id="tranquilizers_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("tranquilizers_days")}
                                            id="tranquilizers_response"
                                            name="tranquilizers_response"
                                            value={this.state.supplementaryInfoData.tranquilizers_response}
                                            className="custom-select"
                                            required={this.state.required.tranquilizers_response}
                                          >
                                            {this.state.tranquilizersResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/*Code By ashraf start  */}
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-12 col-md-12">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="tranquilizers_route"
                                            name="tranquilizers_route"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.tranquilizers_route}
                                            required={this.state.required.tranquilizers_route}
                                          >
                                            {this.state.tranquilizersRouteOption}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    {/* Code by ashraf end */}
                                    <td>
                                      <input
                                        onChange={this.handleSave("")}
                                        id="tranquilizers_days"
                                        readOnly={this.state.supplementaryInfoData.tranquilizers_response !== "Yes"}
                                        name="tranquilizers_days"
                                        type="number"
                                        max="30"
                                        data-msg="Please enter a value between 0 and 30 days"
                                        min="0"
                                        className="form-control"
                                        value={this.state.supplementaryInfoData.tranquilizers_response !== "Yes" ? 0 : this.state.supplementaryInfoData.tranquilizers_days}
                                        required={this.state.required.tranquilizers_days}
                                      />
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <div className="row">
                                        <div className="col-lg-8 col-md-7">
                                          <LabelField
                                            title={this.state.labelText.other_response}
                                            mandatory={this.state.required.other_response}
                                            name="other_response"
                                            tooltip={this.state.tooltips.other_response}
                                            id="other_responseother_response"
                                          />
                                        </div>
                                        <div className="col-lg-4 col-md-5">
                                          <select
                                            onChange={this.handleSave("")}
                                            id="other_response"
                                            name="other_response"
                                            className="custom-select"
                                            value={this.state.supplementaryInfoData.other_response}
                                            required={this.state.required.other_response}
                                          >
                                            {this.state.otherResponseSelectOptions}
                                          </select>
                                        </div>
                                      </div>
                                    </td>
                                    <td></td>
                                  </tr>
                                  {this.state.supplementaryInfoData.other_response === "Yes" ? (
                                    <tr>
                                      <td colspan="1">
                                        <div className="row">
                                          <div className="col-lg-8 col-md-7">
                                            <LabelField
                                              title={this.state.labelText.other_please_specify}
                                              mandatory={this.state.required.other_please_specify}
                                              name="other_please_specify"
                                              tooltip={this.state.tooltips.other_please_specify}
                                              id="other_please_specify"
                                            />
                                          </div>
                                          <div className="col-lg-4 col-md-5">
                                            <input
                                              onChange={this.handleSave("")}
                                              id="other_please_specify"
                                              name="other_please_specify"
                                              type="text"
                                              className="form-control"
                                              placeholder={this.state.labelText.other_please_specify}
                                              value={this.state.supplementaryInfoData.other_please_specify}
                                              required={this.state.required.other_please_specify}
                                              maxLength={this.state.maxLength.other_please_specify}
                                            />
                                          </div>
                                        </div>
                                      </td>
                                      {/*Code By ashraf start  */}
                                      <td>
                                        <div className="row">
                                          <div className="col-lg-12 col-md-12">
                                            <select
                                              onChange={this.handleSave("")}
                                              id="other_route"
                                              name="other_route"
                                              className="custom-select"
                                              value={this.state.supplementaryInfoData.other_route}
                                              required={this.state.required.other_route}
                                            >
                                              {this.state.otherRouteOption}
                                            </select>
                                          </div>
                                        </div>
                                      </td>
                                      {/* Code by ashraf end */}
                                      <td>
                                        <input
                                          onChange={this.handleSave("")}
                                          id="other_days"
                                          name="other_days"
                                          readOnly={this.state.supplementaryInfoData.other_please_specify === ""}
                                          type="number"
                                          max="30"
                                          data-msg="Please enter a value between 0 and 30 days"
                                          min="0"
                                          className="form-control"
                                          value={this.state.supplementaryInfoData.other_please_specify === "" ? 0 : this.state.supplementaryInfoData.other_days}
                                          required={this.state.required.other_days}
                                        />
                                      </td>
                                    </tr>
                                  ) : (
                                    <div>
                                      <input type="hidden" name="other_please_specify" value="" />
                                      <input type="hidden" name="other_days" value={0} />
                                    </div>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer float-right">
                        <div className="float-right">
                        {can_view_version('SupplementaryInformation') ? (<a data-toggle="modal" data-target="#modal-history" className="btn btn-default float-right" onClick={() => this.handleViewHistoryDetails()}> View History</a>) : ''}
                          <button type="submit" className="btn btn-primary float-right btnmargin saveButtonProceed2" value="proceed" onClick={this.handleId} ><img src="dist/img/save-proceed_icon.png" alt="" />{" "} Save &amp; Proceed{" "}</button>
                          <button type="submit" value="save" className="btn btn-success float-right btnmargin saveButton2" onClick={this.handleId} >{" "}<i className="fas fa-save"></i> Save</button>
                        </div>
                      </div>
                    </div>
                  </form>
                </fieldset>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
export default HOC(Supplementaryinfo);
