
import axios from 'axios';
import React, { Component } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { Alert } from "./toastify";


var context;


class Logout extends Component {
  constructor() {
    super();
    context = this;
  }

  componentDidMount = () => {
    const query = new URLSearchParams(this.props.location.search);
    const state = query.get('state');
    const sp = query.get('sp');
    if (sp == undefined || sp == null) {
      //sessionStorage.setItem('last_page_url', this.props.history.location.pathname.substring(1));
      this.logoutFromWS02();
    } else if (state == undefined || state == null) {
      window.location.href = global.domain;
    } else {
      // console.log('clearing session from React')
      sessionStorage.setItem('__TOKEN__', '');
      sessionStorage.removeItem('success_msg');
      sessionStorage.removeItem('error_msg');
      sessionStorage.setItem('loggedinusername', '');
      sessionStorage.setItem('clientId', '');
      sessionStorage.setItem('viewMode', '');
      sessionStorage.setItem('peer_support_specialist', '')
      sessionStorage.setItem('intake_date', '')
      sessionStorage.setItem('dob', '')
      sessionStorage.setItem('month_1_followup_date', '')
      sessionStorage.setItem('email', '')
      sessionStorage.setItem('phone', '')
      sessionStorage.setItem('last_updated_by', '')
      sessionStorage.setItem('formname', '')
      sessionStorage.setItem('responceId', '')
      sessionStorage.setItem('client_first_name', '')
      sessionStorage.setItem('client_middle_initial', '')
      sessionStorage.setItem('client_last_name', '')
      sessionStorage.setItem('client_basicinfosave_url', '')
      sessionStorage.setItem('emergency_contact_save_url', '')
      sessionStorage.setItem('hangout_save_url', '')
      sessionStorage.setItem('other_agency_save_url', '')
      sessionStorage.clear();
      window.location.href = global.domain;
    }
    // sessionStorage.clear();
    // window.location.href = global.domain;
  }


  logoutFromWS02() {
    let WS02Body = {
      userName : sessionStorage.getItem('username'),
      apikey : sessionStorage.getItem('__TOKEN__')
    };
    axios.post(global.restApiURL + 'customRest/WS02Logout', JSON.stringify(WS02Body))
      .then(res => {
        if (res.data.success && res.status == 200) {
          window.location.href = res.data.url;
        } else if ("error_code" in res) {
          Alert('error', 'Error occured while Logging out from WS02. Try again!');
          window.location.href = global.domain + global.traversArray['out'] + '?sp=CHIPSS&state=logout';
        }
        else {
          Alert('error', 'Error occured while Logging out from WS02. Try again!');
          window.location.href = global.domain + global.traversArray['out'] + '?sp=CHIPSS&state=logout';
        }
      })
      .catch(err => { // log request error and prevent access to undefined state
        this.setState({ loading: false, error: true });
        sessionStorage.clear();
        if (err.response) {
          window.location.href = "/";
          if (err.response.status === 401 || err.response.status === 403) {
            //window.location.href = global.domain + global.traversArray['out'];
          }
        } else if (err.request) {
          window.location.href = "/";
        } else {
          window.location.href = "/";
        }
      }
    )
  }

  render() {
    return (
      <div className="hold-transition login-page">
        {/*<div className="login-box" >
            <div className="login-logo">
               <img src={window.location.origin + '/images/dcaris-logo-green.png'} alt="DCARIS" />
                <br/> 
                <br/>
                <img src={window.location.origin + '/images/alert.png'} alt="Logged out" />
                <h4>You are logged out.</h4>
            </div>
          </div>*/}
      </div>
    )
  }
}

export function AppLogout(props) {
  const navigate = useNavigate();
  const location = useLocation()
  return (<Logout {...props} navigate = {navigate} location = {location}></Logout>
  )
}

export default Logout