
import React, { Component } from 'react';
import $ from 'jquery';
import { Link } from 'react-router-dom'
import DatePicker from "react-datepicker";
import Modal from '@trendmicro/react-modal';
import DataTable, { defaultThemes } from 'react-data-table-component';
import '../../../global.js';
import '@trendmicro/react-modal/dist/react-modal.css';
import axios from 'axios';
import moment from 'moment-timezone';

var context = '';
class viewHistoryModal extends Component {

  constructor(props) {
    super(props);
    this.state = {

      viewHistoryModal: this.props.viewHistoryModal,
      class_name: this.props.class_name,
      historyDetails: [],
      historyComparison: [],
      historyComparisonDiff: {},
      checkedRow: [],
      users_list: [],
      class_definition: this.props.class_definition,
      checkedRowLimit: 2,
      obj_id: this.props.obj_id
    }
    console.log(this.state.viewHistoryModal);
    if (this.state.viewHistoryModal === true) {
      this.handleViewHistoryList();
    } else {
      this.setState({ viewHistoryModal: false })
    }
  }

  closeViewHistoryModal = () => {
    //this.setState({viewHistoryModal : false})
    this.setState({ historyComparison: [], historyComparisonDiff: [], checkedRow: [] });
    this.props.onViewHistoryModalClose(false);
  }

  limitSelectedComparison = (event) => {
    let limit = this.state.checkedRowLimit;
    let historyComparison = this.state.historyComparison;
    let checkedRow = this.state.checkedRow;


    //console.log('allowed')
    if (event.target.checked) {
      if (historyComparison.length < limit) {
        if (!historyComparison.includes(event.target.value)) {
          historyComparison.push(parseInt(event.target.value));
          if (historyComparison.length === limit) {
            checkedRow = historyComparison;
          } else {
            checkedRow = [];
          }
        }
      }
    } else {
      for (var i = 0; i < historyComparison.length; i++) { if (historyComparison[i] === parseInt(event.target.value)) { historyComparison.splice(i, 1); } }

      if (historyComparison.length === limit) {
        checkedRow = historyComparison;
      } else {
        checkedRow = [];
      }
    }
    this.setState({ historyComparison, checkedRow });

    if (historyComparison.length >= 1) {
      this.getVersionDifference();
    }

  }

  getDetailInformation = (row) => {
    let version_id = row.versionCount
    this.setState({ historyComparison: [version_id], checkedRow: [] });

    setTimeout(() => this.getVersionDifference(), 100);
  }

  getVersionDifference = () => {

    console.log('called')
    let tempDetails = [];
    let diff = [];
    let historyComparisonDiff = [];
    this.state.historyDetails.map((version) => {
      if (this.state.historyComparison.includes(version.versionCount)) {
        //console.log('found', version.versionCount, this.state.historyComparison);
        tempDetails.push(version);
      } else {
        //console.log('not found', version.versionCount, this.state.historyComparison)
      }
    });

    console.log('temp', tempDetails);

    if (tempDetails.length > 0) {

      Object.keys(tempDetails[0]).map((key) => {

        let temp_arr = [];
        for (let i = 0; i < tempDetails.length; i++) {
          let t = tempDetails[i];
          temp_arr.push(t[key]);
        }

        //console.log('tem_arr', key, temp_arr, tempDetails[0][key]);

        if (this.state.historyComparison.length > 1) {
          if (temp_arr.filter(x => {
            let returnV = '';
            if (Array.isArray(x)) {
              if (x.length !== tempDetails[0][key].length) {
                //console.log('compare length of array', x,x.length, tempDetails[0][key],tempDetails[0][key].length )
                returnV = false;

              } else {
                let checker = (arr, target) => target.every(v => arr.includes(v));

                // console.log('array compare', x, tempDetails[0][key], checker(x, tempDetails[0][key]) )
                returnV = checker(x, tempDetails[0][key]) && checker(tempDetails[0][key], x)
              }
            } else {
              //console.log('compare values', x, tempDetails[0][key]);
              returnV = x === tempDetails[0][key]
            }

            //console.log('result of comparison', returnV);

            return returnV;

          }).length !== tempDetails.length) {
            //console.log('diff', key )
            diff.push(key)
          }
        } else {
          diff = Object.keys(tempDetails[0]);
        }

      })

    }

    console.log('diff', diff);
    if (diff.length > 0) {


      diff.map((k) => {
        if (Object.keys(this.state.class_definition).includes(k)) {
          let filtered_version = { 'name': this.state.class_definition[k] };
          this.state.historyDetails.map((version) => {
            // console.log('version',version);

            if (this.state.historyComparison.includes(version.versionCount)) {
              filtered_version['version_' + version.versionCount] = Array.isArray(version[k]) ? (version[k].map((val) => <li>{val}</li>)) : (k === 'last_updated_by' ? this.state.users_list.filter((user) => parseInt(user.id) === parseInt(version[k]))[0]['name'] : version[k]);

            }


          })
          historyComparisonDiff.push(filtered_version);
        }

      })

    }

    this.setState({ historyComparisonDiff });
  }

  getVersionDifferenceTable = () => {
    let versionComparisonCols = [
      {
        name: 'Name',
        selector: 'name',
        sortable: false,
        wrap: true,

      }];
    let versions_selected = [];
    let i = 1;
    this.state.historyComparison.map((version) => {
      let ver = this.state.historyDetails.filter((v) => v.versionCount === version);
      console.log('date', ver)
      let ver_date = ver[0].modificationDate;
      versions_selected.push({
        name: 'Version dated: ' + ver_date,
        selector: 'version_' + version,
        sortable: false,
        wrap: true
      })

      i++;
    });

    return [...versionComparisonCols, ...versions_selected];
  }

  componentDidMount() {
    this.props.onRef(this);
    context = this;
  }

  componentWillReceiveProps({ viewHistoryModal, class_definition, obj_id, users_list }) {
    console.log('received props', obj_id)

    this.setState({ class_definition: class_definition, obj_id: obj_id, users_list: users_list });
    if (viewHistoryModal === true) {
      this.handleViewHistoryList();
    } else {
      this.setState({ viewHistoryModal: false })
    }
  }

  handleViewHistoryList = () => {
    let historyParams = {
      apikey: sessionStorage.getItem('__TOKEN__'),
      client_id: sessionStorage.getItem('clientId'),
      class_name: this.state.class_name,
      obj_id: this.state.obj_id,
    };
    console.log(historyParams);
    axios.post(global.restApiURL + 'customRest/getObjVersions', JSON.stringify(historyParams))
      .then(res => {

        const response = res.data;

        // if (response.success) {
        //   this.setState({
        //     historyDetails: response.data,
        //     viewHistoryModal: true
        //   })
        // }
        
        if (response.success) {
          // Remove `client_import_status` and `participating_in_mat` keys from each object in the array
          const filteredData = response.data.map(item => {
            // Convert `intake_date` and `dob` to MM/DD/YYYY format
            let formattedIntakeDate = this.formatDate(item.intake_date);
            let formattedDOB = this.formatDate(item.dob);
            let formattedUpdatedon = this.formatDate(item.last_updated_on)
            let formattedhear_rrc_center_ross_helpline=this.formatDate(item.hear_rrc_center_ross_helpline)
            let formattedlast_assess_date=this.formatDate(item.last_assess_date)
            let formattedscheduled_date=this.formatDate(item.scheduled_date)
            
            const { client_import_status, participating_in_mat,hear_rrc_center_ross_helpline,last_assess_date,scheduled_date, ...rest } = item; // Exclude unwanted keys
            return {
              ...rest, // Keep the rest of the object
              intake_date: formattedIntakeDate, // Set the formatted intake_date
              dob: formattedDOB,// Set the formatted dob
              last_updated_on: formattedUpdatedon,
              hear_rrc_center_ross_helpline:formattedhear_rrc_center_ross_helpline,
              last_assess_date:formattedlast_assess_date,
              scheduled_date:formattedscheduled_date
            };
          });

          this.setState({
            historyDetails: filteredData,
            viewHistoryModal: true
          });
        }


        console.log('history Details', context.state.historyDetails);

      })
      .catch(err => { // log request error and prevent access to undefined state
        context.setState({ loading: false, error: true });
        console.error(err);
      })
  }

  formatDate = (date) => {
    if (!date) return ""; // If date is null or undefined, return blank
    const formattedDate = new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',  // Full numeric year (YYYY)
      month: '2-digit', // Two-digit month (MM)
      day: '2-digit'    // Two-digit day (DD)
    });
    return formattedDate;
  }


  render() {

    let viewHistoryColumns = [
      {
        name: 'Date',
        selector: 'modificationDate',
        sortable: false,
        cell: (row) => {
          return moment(row.modificationDate).tz('America/New_York').format("MM/DD/YYYY hh:mm a");
        },

      },
      {
        name: 'User',
        selector: 'userModificationName',
        sortable: true,
      },
      {
        name: 'Compare',
        sortable: false,
        cell: (row) => (
          <div style={{ width: '100%', textAlign: 'left' }}>
            <input type="checkbox" className="historyComparison" value={row.versionCount} name="compare" title="compare" checked={this.state.historyComparison.length > 0 ? (this.state.historyComparison.includes(row.versionCount) ? true : false) : false} onClick={this.limitSelectedComparison} disabled={this.state.checkedRow.length > 0 ? (this.state.checkedRow.includes(row.versionCount) ? false : true) : false} />
          </div>
        ),
        ignoreRowClick: true,
        allowOverflow: true,
        button: true,

      }];


    return (
      <Modal
        showOverlay={true}
        show={this.state.viewHistoryModal}
        onClose={this.closeViewHistoryModal}
        disableOverlayClick={true}
        showCloseButton={true}
        size=""
        className="modal-dialog modal-full"
      >
        <Modal.Header style={{ borderTopLeftRadius: "10px", borderTopRightRadius: "10px" }}>
          <Modal.Title>
            <div className="row" >
              <div className="col-md-12">
                <h4 className="modal-title float-left text-green">View History <small className="text-gray">Here you can refer to various versions of this form filled up for this client</small></h4>

              </div>
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-5 col-lg-5">
              <div className="box">
                <div className="box-header">
                  <label className="lead text-gray">Versions</label>
                  <label class="text-gray">This table lists down all versions available. You can view details for any 1 version or select two and do a comparison </label>
                </div>
                <div className="box-body">
                  <DataTable
                    className='table_Data'
                    columns={viewHistoryColumns}
                    data={this.state.historyDetails}
                    onRowClicked={this.getDetailInformation}
                    customStyles={global.customTableStyles}
                    striped={true}
                    pagination = {this.state.historyDetails ? true : false}
                    paginationComponentOptions={global.paginationComponentOptions}
                    highlightOnHover
                    pointerOnHover
                    noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                  />
                </div>
              </div>

            </div>

            <div className="col-md-7 col-lg-7">
              <div className="box">
                <div className="box-header">
                  <label className="lead text-gray">Details</label>
                  <label class="text-gray">This table shows the details of changes in the selected version(s). If you've chosen two versions it will show what has been changed between those two versions</label>
                </div>
                <div className="box-body">
                  <DataTable
                    className='table_Data'
                    columns={this.getVersionDifferenceTable()}
                    data={this.state.historyComparisonDiff}
                    customStyles={global.customTableStyles}
                    striped={true}
                    pagination = {this.state.historyComparisonDiff.length > 0} 
                    paginationComponentOptions={global.paginationComponentOptions}
                    highlightOnHover
                    pointerOnHover
                    noDataComponent={<div className="no_record_found">{global.no_record_found_text}</div>}
                  />
                </div>
              </div>

            </div>
          </div>
        </Modal.Body>
        <Modal.Footer style={{ borderBottomLeftRadius: "10px", borderBottomRightRadius: "10px" }}>
          <div className="row">
            <div className="col-md-6">
              {/* <div >
                                  <button type="submit" className="btn btn-primary float-right" value="proceed" ><img src="dist/img/save-proceed_icon.png" alt="" style={{ pointerEvents : "none" }} /> Save &amp; Proceed </button>                                          
                                  <button type="submit" value='save' className="btn btn-success float-right btnmargin" > <i className="fas fa-save" style={{ pointerEvents : "none" }}></i> Save</button>
                              </div> */}
              <div className="justify-content-between" >
                <button className="btn btn-default float-left" onClick={this.closeViewHistoryModal} type="button">Close</button>
                {/* <button className="btn btn-success" type="button" style={{display : this.state.formAccessMode}} onClick={context.saveFollowUpdetails} ><i className="fas fa-save"></i>&nbsp;Save</button> */}
              </div>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    )
  }

}

export default viewHistoryModal;
